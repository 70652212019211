import DataObject from '../../utils/DataObject';
import Story from '../Story';
import StoryCounts from './StoryCounts';
import ArrayUtils from '../../utils/ArrayUtils';
import Cube from '../Cube';
const byKey = (arg) => {
    return (s) => {
        return arg.id ? (s.id === arg.id) : (s.identifier === arg.identifier);
    };
};
class Stories extends DataObject {
    static dress(stories, world) {
        if (stories.constructor && stories.constructor === this) {
            return stories;
        }
        if (!world || !world.board) {
            throw new Error('Missing world with board');
        }
        const sWorld = { dimensions: world.board.dimensions };
        return new Stories({
            stories: stories.map((s) => Story.dress(s, sWorld)),
            board: world.board,
            globalContext: world.globalContext,
            __metadata: stories.__metadata || {},
        });
    }
    // Story collection accessors
    getStoryByIdentifier(identifier) {
        return this.stories.find((s) => {
            return s.identifier === identifier;
        });
    }
    getStoryById(id) {
        return this.stories.find(s => s.id === id);
    }
    getKnownIds(ids) {
        const known = [];
        this.stories.forEach((s) => {
            if (ids.indexOf(s.id) >= 0) {
                known.push(s.id);
            }
        });
        return known;
    }
    knows(story) {
        return !!this.getStoryById(story.id);
    }
    get all() {
        return this.stories;
    }
    get filtered() {
        return this.memoize('filtered', '', () => {
            let filtered = this.stories.filter((s) => {
                return this.board.filters.accept(s, this.board.dimensions, this.globalContext);
            });
            const search = this.board.search;
            if (search) {
                const regexp = new RegExp(search.replace(/\s+/, '|'), 'im');
                filtered = filtered.filter((s) => {
                    return (s.specification && s.specification.match(regexp))
                        || this.board.dimensions.some((d) => d.searchMatchesOn(search, regexp, s, this.globalContext));
                });
            }
            const sorter = this.sorter ?? this.board.getStoriesSorter(this.globalContext);
            // @ts-expect-error fix typescript
            return filtered.sort(sorter);
        });
    }
    get visible() {
        // @ts-expect-error fix typescript
        return this.memoize('visible', this.limitTo, () => {
            if (this.limitTo && this.filtered.length > this.limitTo) {
                return this.filtered.slice(0, this.limitTo);
            }
            else {
                return this.filtered;
            }
        });
    }
    // Story counts facade
    get storyCounts() {
        return this.memoize('storyCounts', '', () => {
            return new StoryCounts(this);
        });
    }
    storyCount(dimension, value) {
        return this.storyCounts.getCounts(dimension, value);
    }
    // Enumerable
    forEach(callback) {
        return this.filtered.forEach(callback);
    }
    map(callback) {
        return this.filtered.map(callback);
    }
    filter(callback) {
        return this.filtered.filter(callback);
    }
    // selection
    forSelection(selection, filteringCallback) {
        const ss = selection.toIds().map(id => this.getStoryById(id)).filter(Boolean);
        return filteringCallback ? ss.filter(filteringCallback) : ss;
    }
    // Functional updates
    storyAdded(story) {
        return this.storyChanged(story, false);
    }
    storyChanged(story, mustExist = true) {
        const index = this.stories.findIndex((s) => {
            return story.id ? (s.id === story.id) : (s.identifier === story.identifier);
        });
        if (index < 0 && mustExist) {
            throw new Error(`No such story ${story.id}`);
        }
        else if (index >= 0 || !mustExist) {
            const clonedStories = this.stories.slice();
            clonedStories[index < 0 ? this.stories.length : index] = story;
            return this.clone({
                stories: clonedStories,
            }, false);
        }
        else {
            return this;
        }
    }
    storyDataChanged(storyData, mustExist = true) {
        const story = this.getStoryByIdentifier(storyData.identifier);
        if (story) {
            return this.storyChanged(story.clone(storyData), mustExist);
        }
        else if (mustExist) {
            throw new Error(`No such story ${storyData.id}`);
        }
    }
    storyDeleted(story, mustExist = true) {
        const index = this.stories.findIndex(byKey(story));
        if (index < 0 && mustExist) {
            throw new Error(`No such story ${story.id}`);
        }
        else if (index >= 0) {
            return this.clone({
                stories: ArrayUtils.deleteAt(this.stories, index),
            }, false);
        }
        else {
            return this;
        }
    }
    storiesAdded(stories, mustExist = true) {
        if (stories.length === 0) {
            return this;
        }
        return stories.reduce((memo, s) => {
            return memo.storyAdded(s, mustExist);
        }, this);
    }
    storiesChanged(stories, mustExist = true) {
        if (stories.length === 0) {
            return this;
        }
        return stories.reduce((memo, s) => {
            return memo.storyChanged(s, mustExist);
        }, this);
    }
    storiesDeleted(stories, mustExist = true) {
        if (stories.length === 0) {
            return this;
        }
        return stories.reduce((memo, s) => {
            return memo.storyDeleted(s, mustExist);
        }, this);
    }
    storiesEmptied() {
        return this.clone({ stories: [] }, false);
    }
    // Limiting
    withSorter(sorter) {
        return this.clone({
            sorter: sorter,
        }, false);
    }
    withLimitTo(maxStories) {
        return this.clone({
            limitTo: maxStories,
        }, true);
    }
    withNoLimit() {
        return this.clone({
            limitTo: 5000,
        }, true);
    }
    isLimiting() {
        return !!(this.limitTo && this.visible.length < this.filtered.length);
    }
    isLimitedByAPI() {
        return !!this.__metadata['stories-limited'];
    }
    partialResults() {
        return !!this.__metadata['stories-limited'] || !!this.__metadata['stories-searchapplied'];
    }
    // Patching
    withStoryPatchApplied(patch, mustExist = true) {
        const index = this.stories.findIndex(byKey(patch));
        if (index < 0) {
            if (mustExist) {
                throw new Error(`No such story for ${JSON.stringify(patch)}`);
            }
            else {
                return this;
            }
        }
        else {
            const clonedStories = this.stories.slice();
            clonedStories[index] = clonedStories[index].clone(patch);
            return this.clone({
                stories: clonedStories,
            }, false);
        }
    }
    withStoriesPatchApplied(patch, mustExist = true) {
        return patch.toRaw().reduce((memo, p) => {
            return memo.withStoryPatchApplied(p, mustExist);
        }, this);
    }
    withUpdatedDimension(dimension) {
        const oldDim = this.board.dimensionById(dimension.id);
        if (!oldDim) {
            return this;
        }
        else if (oldDim.code === dimension.code) {
            return this;
        }
        else {
            return this.clone({
                stories: this.stories.map(s => s.withDimensionRenamed(oldDim.code, dimension.code)),
            }, false);
        }
    }
    withDeletedDimension(dimension) {
        const oldDim = this.board.dimensionById(dimension.id);
        if (!oldDim) {
            return this;
        }
        else {
            return this.clone({
                stories: this.stories.map(s => s.withDimensionRemoved(dimension.code)),
            }, false);
        }
    }
    // Statistics
    rollup(bys, globalContext) {
        return new Cube(this, this.visible, bys.map((by) => this.board.dimension(by)), {
            summarize: true,
            globalContext: globalContext,
        });
    }
    // Technical tools
    // Deprecated, used in tests only
    reset(board) {
        return this.clone({ board: board }, false);
    }
}
export default Stories;
