import { User } from '@klaro/corejs/model';
angular
    .module('klaro')
    .service('profileModal', profileModal)
    .controller('ProfileModalController', ProfileModalController);
function profileModal(klaroModal) {
    return {
        open: function (options) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/auth/profileModal.html'),
                controller: 'ProfileModalController',
                windowClass: 'klaro-modal profile-modal',
                size: 'small',
                resolve: {
                    options: function () { return options || {}; },
                },
            });
        },
    };
}
function ProfileModalController($scope, $uibModalInstance, auth, options, robust) {
    $scope.ctrl = this;
    this.langs = User.supportedLanguages;
    // Reloads the modal in a given mode and resets everything
    this.load = function (mode) {
        $scope.user = auth.getUser();
        $scope.mode = mode ? mode : 'menu';
        $scope.error = null;
        $scope.userData = $scope.user.toRaw();
    };
    this.load(options.mode);
    this.patchUser = function () {
        delete $scope.userData.workspaces;
        return auth.updateProfile($scope.userData);
    };
    // Used for modal parts to track errors
    this.clearError = function () {
        $scope.error = null;
    };
    this.onError = function (err) {
        $scope.error = robust.message(err);
    };
    // Main scope for the menu
    this.switchMode = function (mode) {
        $scope.mode = mode;
    };
    $scope.switchMode = this.switchMode;
    this.cancel = $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    this.close = $scope.close = function () {
        $uibModalInstance.close();
    };
}
