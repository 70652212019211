angular
    .module('klaro')
    .directive('acceptTerms', acceptTerms);
function acceptTerms() {
    return {
        restrict: 'EA',
        scope: {
            ngModel: '=',
            name: '@',
        },
        replace: true,
        template: require('@/auth/acceptTerms.html'),
    };
}
