import { User } from '../../../model';
export class ApiAuthenticationConnector {
    constructor(client) {
        this.client = client;
        this.tokens = {
            get: async (args) => {
                const tokenRes = await client.api.post('/auth/tokens/', {
                    grant_type: 'auto_login',
                    client_id: args.login,
                    client_secret: args.password,
                }, {
                    headers: {
                        'X-Klaro-Project-Subdomain': 'app',
                    },
                });
                return tokenRes.data;
            },
            refresh: async (args) => {
                const tokenRes = await client.api.post('/auth/tokens/refresh/', {}, {
                    headers: {
                        'X-Klaro-Project-Subdomain': 'app',
                        'Authorization': `${args.token.token_type} ${args.token.access_token}`
                    },
                });
                return tokenRes.data;
            },
        };
        this.me = {
            async get() {
                const res = await client.api.get('/auth/me');
                return User.dress(res.data);
            }
        };
    }
}
