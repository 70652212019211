import { ChartType } from '../../../model';
import { DataObject } from '../../../utils';
export const ChartViewVariants = {
    [ChartType.bar]: {
        label: 'Bar Chart',
        chart: {
            type: 'column'
        }
    },
    [ChartType.line]: {
        label: 'Line Chart',
        chart: {
            type: 'line'
        }
    },
    [ChartType.radar]: {
        label: 'Radar Chart',
        chart: {
            type: 'line',
            polar: true,
        }
    },
};
export class ChartView extends DataObject {
    constructor(data) {
        super(Object.assign({}, data));
    }
    getDisplayByDimension() {
        return this.board.getDimensionAt('displayBy');
    }
    getSeriesByDimension() {
        return this.board.getDimensionAt('seriesBy');
    }
    getCategoryValues(displayBy, globalContext) {
        const cube = this.stories.rollup([displayBy].filter(Boolean), globalContext);
        return cube.columns.map(c => c.dimensionValue);
    }
    getTypeCode() {
        return this.board.extraSettings?.chartOptions?.type || ChartType.bar;
    }
    getType() {
        return ChartViewVariants[this.getTypeCode()];
    }
    toHighchartsChartEntry(overrides) {
        return Object.assign({
            margin: 100,
            ...overrides,
        }, this.getType().chart);
    }
    toHighcharts(globalContext, overrides) {
        const displayBy = this.getDisplayByDimension();
        const seriesBy = this.getSeriesByDimension();
        const categoryValues = this.getCategoryValues(displayBy, globalContext);
        const cube = this.stories.rollup([seriesBy, displayBy].filter(Boolean), globalContext);
        const chart = this.toHighchartsChartEntry(overrides);
        const series = this.toHighchartsSeries(chart, cube, seriesBy, categoryValues);
        return {
            chart,
            title: {
                text: ''
            },
            xAxis: {
                categories: categoryValues.map(v => v.label),
            },
            plotOptions: {},
            series: series,
        };
    }
    toHighchartsSeries(chart, cube, seriesBy, categoryValues) {
        if (seriesBy) {
            const multiSummary = (cube.summaries.length > 1);
            return cube.columns.reduce((memo, child) => {
                const series = this._toHighchartsSeries(chart, child, categoryValues, (summary) => {
                    if (multiSummary) {
                        return `${child.dimensionValue.label} (${summary.label})`;
                    }
                    else {
                        return child.dimensionValue.label;
                    }
                });
                return memo.concat(series);
            }, []);
        }
        else {
            return this._toHighchartsSeries(chart, cube, categoryValues, (summary) => {
                return summary.label;
            });
        }
    }
    _toHighchartsSeries(chart, cube, categoryValues, labeler) {
        return cube.summaries.map(summary => {
            return {
                type: chart.type,
                name: labeler(summary),
                data: categoryValues.map(categoryValue => {
                    const child = cube.getColumnFor(categoryValue);
                    return child ? child.summary[summary.code] : 0;
                }),
            };
        });
    }
}
