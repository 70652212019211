angular
    .module('klaro')
    .directive('storyMatrix', storyMatrix);
function storyMatrix(ActionsForHelper, klaroUi, events) {
    return {
        restrict: 'E',
        template: require('@/core/story/storyMatrix.html'),
        scope: {
            'onClick': '&',
            'canWrite': '=',
        },
        require: '^board',
        link: function (scope, elm, attrs, boardCtrl) {
            scope.boardCtrl = boardCtrl;
            function onBoardChanged() {
                const globalContext = boardCtrl.getGlobalContext();
                scope.matrixView = boardCtrl.getBoardState().matrixView;
                scope.rowsCube = scope.matrixView?.getRowsCube(globalContext);
                scope.columnsCube = scope.matrixView?.getColumnsCube(globalContext);
            }
            boardCtrl.$watch('board', onBoardChanged, scope);
            scope.withNewColumnDimension = function (dimension) {
                return boardCtrl.mutate((b) => {
                    return b.addNewDimension(dimension).setDimensionsAt('displayBy', [dimension]);
                });
            };
            scope.withNewRowDimension = function (dimension) {
                return boardCtrl.mutate((b) => {
                    return b.addNewDimension(dimension).setDimensionsAt('rowsBy', [dimension]);
                });
            };
            scope.configureIt = function () {
                return boardCtrl.mutate((board) => {
                    return board
                        .setDimensionsAt('displayBy', [scope.matrixView.configuration.columnsBy])
                        .setDimensionsAt('rowsBy', [scope.matrixView.configuration.rowsBy]);
                });
            };
            scope.columnActionsFor = function (column) {
                if (!column.actions) {
                    column.actions = ActionsForHelper.actionsForMatrixColumn(boardCtrl, column);
                }
                return column.actions;
            };
            scope.cardActionsFor = function (row, column) {
                const cell = scope.matrixView.cellFor(row, column);
                if (!cell) {
                    return;
                }
                if (!cell.actions) {
                    cell.actions = ActionsForHelper.actionsForMatrixCell(boardCtrl, cell);
                }
                return cell.actions;
            };
            scope.patchOnDrop = function (row, column) {
                return function (storyIds) {
                    const dimensions = [row.dimension, column.dimension];
                    const values = [row.dimensionValue, column.dimensionValue];
                    scope.boardCtrl.moveStoriesToMultiple(storyIds, dimensions, values);
                };
            };
            scope.defaultsFor = function (row, column) {
                return Object.assign({}, row.defaults, column.defaults);
            };
            scope.withNewDimensionValue = function (dimension, dimensionValue) {
                boardCtrl.addDimensionValue(dimension, dimensionValue, true);
            };
            // Scroll handling
            const evtElm = $('main .content')[0];
            const matrixElm = elm.closest('.stories-container');
            const main = klaroUi.isMobile() ? evtElm : $('main .content')[0];
            // Adapt left position according to scroll and decks opening. Scroll left is
            // implemented in a browser-compatible way (we hope; see google/stackoverflow).
            function onScrollEventHandler() {
                const scrollY = main.scrollTop || $('body').scrollTop() || 0;
                const methodY = scrollY > 20 ? 'addClass' : 'removeClass';
                matrixElm[methodY]('scrolled-down');
                const scrollX = main.scrollLeft || $('body').scrollLeft() || 0;
                const methodX = scrollX > 20 ? 'addClass' : 'removeClass';
                matrixElm[methodX]('scrolled-left');
            }
            // register on scroll
            events.observeElement(scope, 'scroll', onScrollEventHandler, evtElm);
            // Listen to the board and re-adapt column positions when it changes
            // (and hence is refreshed)
            boardCtrl.$watch('board', onScrollEventHandler, scope);
        },
    };
}
