import { Story } from '../../../model';
import { CreateStories } from './CreateStories';
export class DuplicateStories {
    constructor() {
        this.preconditions = [
            async (client) => {
                return !!client.store.board?.board.id && !!client.store.board?.stories;
            }
        ];
    }
    async run(client, args) {
        const stories = client.store.board.stories.forSelection(args.selection);
        const copied = stories.map(s => new Story(s.duplicate(args.patch), s.__world));
        const op = new CreateStories();
        await client.run(op, { stories: copied });
        this._result = op.result;
    }
    get result() {
        return this._result;
    }
}
;
