import { TimeUtils } from '../utils';
import { Session } from '../model';
import { KlaroConnector, factorConnector } from './connectors';
export const createKlaroStore = (client, overrides) => {
    const store = {
        session: new Session({}),
        connector: factorConnector(client, KlaroConnector.api),
        ...overrides,
        async with(patch) {
            return createKlaroStore(client, {
                ...this,
                ...patch
            });
        },
        toGlobalContext() {
            return {
                apiOptions: overrides?.apiOptions,
                session: this.session,
                board: this.board?.board,
                project: this.project,
                now: TimeUtils.now(),
                today: TimeUtils.today(),
            };
        }
    };
    return store;
};
