import { DataObject, ArrayUtils } from '../../../utils';
class CasinoView extends DataObject {
    hasNonTrivialSummary() {
        return this.board.hasSummaries() && !this.board.getTotalCardsSummary();
    }
    getTopCard() {
        return this.getStoriesToDeal()[0];
    }
    getDisplayByDimension() {
        return this.memoize2('getDisplayByDimension', () => {
            return this.board.getDimensionsAt('displayBy')[0];
        }, ['board']);
    }
    getCardsDecks(globalContext = {}) {
        return this.memoize2('getCardsDecks', () => {
            const atCasino = this.getDisplayByDimension();
            if (atCasino) {
                const updatedAt = this.board.getUpdatedAtDimension();
                const sorter = ArrayUtils.sorterBy([updatedAt.getStorySorter(true, globalContext)]);
                return this
                    .stories
                    .withSorter(sorter)
                    .rollup([atCasino], globalContext)
                    .columns;
            }
            else {
                return [{
                        dimensionValue: {},
                        stories: [],
                    }];
            }
        }, ['board', 'stories']);
    }
    getAssignedStories(globalContext = {}) {
        return this.getCardsDecks(globalContext)
            .filter(c => !!c.dimensionValue.id)
            .map(deck => {
            deck.story = deck.stories[0];
            return deck;
        });
    }
    getUnassignedStories(globalContext = {}) {
        const cardsDecks = this.getCardsDecks(globalContext);
        return cardsDecks.filter(c => !c.dimensionValue.id).shift() ?? {
            dimension: cardsDecks[0].dimension,
            dimensionValue: { id: null },
            stories: [],
        };
    }
    getStoriesToDeal(globalContext = {}) {
        const sorter = this.board.getStoriesSorter(globalContext);
        return this.getUnassignedStories(globalContext).stories
            .filter(s1 => !this.folded.some(s2 => s2 === s1.id))
            .sort(sorter);
    }
    getStoriesToDecide(globalContext = {}) {
        return this.getUnassignedStories(globalContext).stories
            .filter(s1 => this.folded.some(s2 => s2 === s1.id))
            .sort((a, b) => this.folded.indexOf(b.id) - this.folded.indexOf(a.id));
    }
    /// Controller
    withTopCardFolded() {
        const top = this.getTopCard();
        if (top) {
            return this.withCardsFolded([top.id]);
        }
        else {
            return this;
        }
    }
    withCardsFolded(cardIds) {
        return this.clone({
            folded: ArrayUtils.union(this.folded, cardIds),
        }, false);
    }
    withAllUnfolded() {
        return this.clone({
            folded: [],
        });
    }
    withOneUnfolded() {
        return this.clone({
            folded: ArrayUtils.deleteAt(this.folded, this.folded.length - 1),
        });
    }
}
export default CasinoView;
