import Dimension from '../Dimension';
import Scalar from './Mixins/Scalar';
import { IntRangeExprParser } from '../../lang';
const parser = new IntRangeExprParser();
const INT_RANGE_EXPR_CACHE = {};
const NONE = function () { return false; };
class Integer extends Dimension {
    static getRawFromHighValue(high) {
        return high;
    }
    /**
      * Overriden to return the raw value as high-level one.
      */
    getStoryHighLevelValue(story, globalContext) {
        return this.getStoryRawValue(story);
    }
    getStoryHumanValue(story, _globalContext) {
        const raw = this.getStoryRawValue(story);
        return raw ? raw : '';
    }
    /**
     * Returns a patch to apply on a story to get it having the specific value.
     */
    getPatchForStoryValueTo(value, _globalContext) {
        const matcher = Integer.getMatcherForExpr(value.semantics);
        const update = {};
        if (matcher && matcher.valueFor) {
            update[this.code] = matcher.valueFor(value);
        }
        return update;
    }
    /**
      * Overriden to take the left interval value of `dimValue`'s semantics and
      * setting it as story raw dimension value.
      */
    withStoryValueTo(story, dimValue, globalContext) {
        const update = this.getPatchForStoryValueTo(dimValue, globalContext);
        return story.clone(update);
    }
    /**
      * Overriden to implement the integer range semantics: the match occurs when
      * the raw dimension value is within `dimValue`'s range.
      */
    storyValueMatches(story, dimValue, _globalContext) {
        const storyValue = this.getStoryRawValue(story);
        if (storyValue == null) {
            return dimValue.id == null;
        }
        if (dimValue.id == null) {
            return false;
        }
        const matcher = Integer.getMatcherForExpr(dimValue.semantics);
        return matcher.match(storyValue);
    }
    /**
      * Overriden to use an `integer` input widget.
      */
    getInputWidgetCode() {
        return 'integer';
    }
    /**
     * +1
     */
    swipeRight(story) {
        const data = {};
        data[this.code] = (story[this.code] + 1);
        return story.clone(data);
    }
    /**
     * -1
     */
    swipeLeft(story) {
        const data = {};
        data[this.code] = (story[this.code] - 1);
        return story.clone(data);
    }
    getTableAlignmentClass() {
        return 'align-right';
    }
    static getMatcherForExpr(expr) {
        if (INT_RANGE_EXPR_CACHE[expr] === undefined) {
            INT_RANGE_EXPR_CACHE[expr] = Integer.compileExpr(expr);
        }
        return INT_RANGE_EXPR_CACHE[expr];
    }
    static validExpr(expr) {
        if (!expr) {
            return false;
        }
        try {
            const r = parser.parse(expr);
            return r !== undefined;
        }
        catch (ex) {
            return false;
        }
    }
    static compileExpr(expr) {
        if (!expr) {
            return NONE;
        }
        try {
            const ast = parser.parse(expr);
            if (!ast)
                return NONE;
            return parser.compileToMatcher(ast);
        }
        catch (ex) {
            console.log(ex);
            return NONE;
        }
    }
}
Object.assign(Integer.prototype, Scalar);
export default Integer;
