import { setupHighcharts } from '@klaro/corejs/state';
import Highcharts from 'highcharts';
import angular from 'angular';
angular
    .module('klaro')
    .directive('storyChart', storyChart);
setupHighcharts();
function storyChart() {
    return {
        restrict: 'E',
        template: require('@/core/story/storyChart.html'),
        scope: {
            'onClick': '&',
            'canWrite': '=',
        },
        require: '^board',
        link: function (scope, elm, attrs, boardCtrl) {
            scope.chartDimension = null;
            function setChartDimension() {
                const atChart = boardCtrl.getDimensionAt('displayBy');
                if (atChart) {
                    scope.chartDimension = atChart;
                }
                else {
                    scope.boardCtrl = boardCtrl;
                    scope.configure = {};
                    scope.dimensions = boardCtrl.getRelevantDimensionsForAnchor('displayBy');
                    scope.chartDimension = null;
                }
            }
            boardCtrl.$watch('board', () => {
                setChartDimension();
                const globalContext = boardCtrl.getGlobalContext();
                const chart = boardCtrl.getBoardState().chartView;
                if (scope.chartDimension && chart) {
                    Highcharts.chart('highchartsContainer', chart.toHighcharts(globalContext));
                }
            }, scope);
            scope.configureCasino = function (dimension) {
                return boardCtrl.mutate((b) => {
                    return b.addNewDimension(dimension).setDimensionsAt('displayBy', [dimension]);
                });
            };
            scope.setDisplayBy = function () {
                boardCtrl.setDimensionsAt('displayBy', [scope.configure.dimension]);
            };
        },
    };
}
