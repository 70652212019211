angular
    .module('klaro')
    .service('storiesDuplicateModal', storiesDuplicateModal)
    .controller('StoriesDuplicateModalController', StoriesDuplicateModalController);
function storiesDuplicateModal(klaroModal) {
    return {
        open: function (boardCtrl, options) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/story/storiesDuplicateModal.html'),
                controller: 'StoriesDuplicateModalController',
                windowClass: 'klaro-modal story-selection-edit-modal',
                size: 'small full-mobile',
                resolve: {
                    boardCtrl: function () {
                        return boardCtrl;
                    },
                    options: function () {
                        return options;
                    },
                },
            });
        },
    };
}
function StoriesDuplicateModalController($scope, $uibModalInstance, boardCtrl) {
    $scope.editableDimensions = boardCtrl.getDimensions()
        .filter(d => d.canBeEditedAsCardDimension());
    $scope.patch = {};
    $scope.selectionSize = boardCtrl.selectionSize.bind(boardCtrl);
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.close = function () {
        $uibModalInstance.close();
    };
    $scope.canSubmit = function () {
        return true;
    };
    $scope.submit = function () {
        boardCtrl
            .duplicateSelection($scope.patch)
            .then($scope.close);
    };
}
