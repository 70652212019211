import { DataObject } from '../../../utils';
export class MatrixView extends DataObject {
    constructor(data) {
        super(Object.assign({}, data));
        this.configuration = {
            columnsBy: this.columnsBy?.code,
            rowsBy: this.rowsBy?.code,
        };
        this.usableDimensions = this.board.getRelevantDimensionsForAnchor('displayBy');
    }
    get isConfigured() {
        return this.rowsBy && this.columnsBy;
    }
    get isCompact() {
        return this.board.compactDisplay;
    }
    get isReadOnly() {
        return !this.rowsBy.canBeEdited() && !this.columnsBy.canBeEdited();
    }
    get rowsBy() {
        return this.memoize2('rowsBy', () => {
            return this.board.getDimensionAt('rowsBy');
        }, ['board']);
    }
    getRowsCube(globalContext) {
        return this.memoize2('rowsCube', () => {
            return this.stories.rollup([this.rowsBy, this.columnsBy].filter(Boolean), globalContext);
        }, ['board', 'stories']);
    }
    get canAddRow() {
        return this.rowsBy.tagging;
    }
    get columnsBy() {
        return this.memoize2('columnsBy', () => {
            return this.board.getDimensionAt('displayBy');
        }, ['board']);
    }
    getColumnsCube(globalContext) {
        return this.memoize2('columnsCube', () => {
            return this.stories.rollup([this.columnsBy].filter(Boolean), globalContext);
        }, ['board', 'stories']);
    }
    get canAddColumn() {
        return this.columnsBy.tagging;
    }
    cellFor(row, column) {
        return row.getColumnFor(column.dimensionValue);
    }
    storiesFor(row, column) {
        const cell = this.cellFor(row, column);
        if (!cell)
            return [];
        return cell.stories;
    }
}
