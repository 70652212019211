angular
    .module('klaro')
    .directive('gravatar', gravatar);
function gravatar() {
    return {
        scope: {
            'user': '=',
            'size': '@',
        },
        restrict: 'E',
        template: require('@/auth/gravatar.html'),
        link: function (scope) {
            scope.sizeAttr = scope.size || '30';
        },
    };
}
