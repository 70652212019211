import { useStoryDimensionValue } from '@klaro/ui/src/composables/StoryDimensionValue';
angular
    .module('klaro')
    .directive('storyDimValue', storyDimValue);
function storyDimValue(navigation, tmhDynamicLocale) {
    return {
        restrict: 'E',
        scope: {
            'inBoard': '=',
            'story': '=',
            'dimension': '=',
        },
        template: require('@/core/story/storyDimValue.html'),
        link: function (scope) {
            let board, dim, watcher;
            function install() {
                board = scope.inBoard;
                if (!board) {
                    return;
                }
                if (watcher) {
                    watcher();
                }
                watcher = scope.inBoard.$watch('board', (b, oldB) => {
                    if (!b || !oldB) {
                        return;
                    }
                    if (b.displayNoneOnCards !== oldB.displayNoneOnCards) {
                        install();
                    }
                }, scope);
                dim = board.dimension(scope.dimension);
                if (!dim) {
                    return;
                }
                const locale = tmhDynamicLocale.get();
                scope.dim = dim;
                scope.cssClass = dim.colored ? '' : 'uncolored';
                const { getViewValues } = useStoryDimensionValue({
                    story: scope.story,
                    board: board.getBoardState().board,
                    dimension: dim,
                    globalContext: board.getGlobalContext(),
                });
                scope.viewValues = getViewValues(locale);
            }
            scope.$watch('inBoard', install);
            scope.clickedOn = function (value, $event) {
                const onClick = dim.datatypeOptions.onClick;
                if (onClick && board.canWrite()) {
                    $event.preventDefault();
                    $event.stopPropagation();
                    const original = scope.story;
                    const changed = dim[onClick](original);
                    board.saveStory(changed, original).catch(navigation.failed);
                }
            };
        },
    };
}
