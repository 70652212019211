import Node from './Node';
import Cube from '../model/Cube';
class PrintGroupBy extends Node {
    toHtml(stories, args) {
        const cube = this.cube(stories);
        if (cube) {
            return cube.columns
                .map((column) => this.columnToHtml(column, args))
                .join('\n');
        }
        else {
            return `
        <section>
          ${this.yield(stories, args)}
        </section>
      `;
        }
    }
    columnToHtml(column, args) {
        const hLevel = args && args.hLevel || 1;
        const title = this.escapeHtml(column.dimensionValue.label);
        const cssClass = this.options.pageBreaks ? ' class="page-break"' : '';
        return `
      <section${cssClass}>
        <h${hLevel}>${title}</h${hLevel}>
        ${this.yield(column.stories, this.withNextHLevel(args))}
      </section>
    `;
    }
    cube(stories) {
        if (!this.options.by) {
            return;
        }
        if (this.options.by.length === 0) {
            return;
        }
        return new Cube(this, stories, this.options.by, {
            globalContext: this.globalContext,
        });
    }
}
export default PrintGroupBy;
