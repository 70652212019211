angular
    .module('klaro')
    .directive('boardInviteStepMany', boardInviteStepMany)
    .directive('emailList', emailList);
function boardInviteStepMany($timeout) {
    return {
        restrict: 'E',
        template: require('@/core/board/invite-steps/boardInviteStepMany.html'),
        link: function (scope, elm, attrs) {
            scope.many = { list: null };
            scope.switchToMany = function () {
                scope.step = 'many';
                $timeout(() => {
                    elm.find('textarea#email-list')[0].focus();
                });
            };
            scope.canConfigureMany = function () {
                return scope.many.list && scope.many.list.length > 0;
            };
            scope.configureMany = function () {
                scope.many.addresses = scope.many.list
                    .split(/[,\n\s]/)
                    .map(s => s.trim())
                    .filter(s => s.length);
                scope.step = 'configure';
            };
            scope.unselectMany = function () {
                scope.many.addresses = null;
                scope.step = 'many';
            };
        },
    };
}
function emailList() {
    const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$validators.emailList = function (value) {
                if (!value) {
                    return true;
                } // Allow empty field
                const emails = value.split(/[,\n\s]+/).map(email => email.trim());
                return emails.every(email => EMAIL_REGEX.test(email));
            };
        },
    };
}
