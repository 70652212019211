angular
    .module('klaro')
    .directive('passwordValidator', PasswordValidator);
function PasswordValidator() {
    return {
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {
            ngModel.$validators.passwordValidator = function (password) {
                if (!password) {
                    return false;
                }
                const length = password.length;
                if (length < 12 || length >= 1000) {
                    return false;
                }
                const digit = /\d/;
                const uppercase = /[A-Z]/;
                const space = /\s/;
                const symbol = /[^a-zA-Z0-9\s]/;
                const matchCount = [digit, uppercase, space, symbol]
                    .map(regex => regex.test(password))
                    .reduce((sum, match) => sum + (match ? 1 : 0), 0);
                if (length < 15 && matchCount < 2) {
                    return false;
                }
                if (length >= 15 && matchCount < 1) {
                    return false;
                }
                return true;
            };
        },
    };
}
