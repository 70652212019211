import nearley from 'nearley';
import { DateTime, Duration } from 'luxon';
import grammar from './Parser.ne.js';
class Fake {
    plus() { return this; }
    minus() { return this; }
}
const SOT = new Fake();
const EOT = new Fake();
const nowFn = (now) => now;
const sotFN = (_now) => SOT;
const eotFN = (_now) => EOT;
class Matcher {
    constructor(parser, start, end, exclusive) {
        this.parser = parser;
        this.start = start;
        this.end = end;
        this.compiled = this.compile(start, end, exclusive);
    }
    compile(sP, eP, ex) {
        const t = () => true;
        const f = () => false;
        const lte = (a, now) => sP(now) <= a;
        const gte = (a, now) => a <= eP(now);
        const gt = (a, now) => a < eP(now);
        const cs = sP === sotFN ? t : (sP === eotFN ? f : lte);
        const ce = eP === eotFN ? t : (eP === sotFN ? f : (ex ? gt : gte));
        return (a, now) => cs(a, now) && ce(a, now);
    }
    match(against, now = this.parser.now()) {
        return this.compiled(against, now);
    }
    valueFor(now = this.parser.now()) {
        return this.start(now);
    }
}
export class TimeRangeExprParser {
    constructor(zone = 'Europe/Brussels', locale = 'fr-BE') {
        this.zone = zone || 'Europe/Brussels';
        this.locale = locale || 'fr-BE';
        this.parser = TimeRangeExprParser.aParser();
    }
    static aParser() {
        const g = nearley.Grammar.fromCompiled(grammar);
        g.start = 'time_range_expression';
        return new nearley.Parser(g);
    }
    inTimezone(instant) {
        return instant.setZone(this.zone).setLocale(this.locale);
    }
    now() {
        return this.inTimezone(DateTime.now());
    }
    fromISO(iso) {
        return this.inTimezone(DateTime.fromISO(iso));
    }
    parse(input) {
        try {
            this.parser.feed(input);
            return this.parser.results[0];
        }
        finally {
            this.parser = TimeRangeExprParser.aParser();
        }
    }
    compileToInstant(ast, base) {
        switch (ast.type) {
            case 'year_instant':
                const year = this.fromISO(ast.value).startOf('year');
                return (_now) => year;
            case 'month_instant':
                const month = this.fromISO(ast.value).startOf('month');
                ;
                return (_now) => month;
            case 'date_instant':
                const date = this.fromISO(ast.value).startOf('day');
                ;
                return (_now) => date;
            case 'constant_instant':
                switch (ast.value) {
                    case 'BOT':
                    case 'SOT':
                        return sotFN;
                    case 'NOW':
                        return (now) => now;
                    case 'TODAY':
                        return (now) => now.startOf('day');
                    case 'YESTERDAY':
                        return (now) => now.minus({ days: 1 }).startOf('day');
                    case 'TOMORROW':
                        return (now) => now.plus({ days: 1 }).startOf('day');
                    case 'SOH':
                        return (now) => now.startOf('hour');
                    case 'SOD':
                        return (now) => now.startOf('day');
                    case 'SOW':
                        return (now) => now.startOf('week');
                    case 'SOM':
                        return (now) => now.startOf('month');
                    case 'SOQ':
                        return (now) => now.startOf('quarter');
                    case 'SOY':
                        return (now) => now.startOf('year');
                    case 'EOH':
                        return (now) => now.endOf('hour');
                    case 'EOD':
                        return (now) => now.endOf('day');
                    case 'EOW':
                        return (now) => now.endOf('week');
                    case 'EOM':
                        return (now) => now.endOf('month');
                    case 'EOQ':
                        return (now) => now.endOf('quarter');
                    case 'EOY':
                        return (now) => now.endOf('year');
                    case 'EOT':
                        return eotFN;
                }
                break;
            case 'computed_instant':
                const left = this.compileToInstant(ast.value.left);
                const right = this.compileToDuration(ast.value.right);
                switch (ast.value.op) {
                    case '+':
                        return (now) => left(now).plus(right(now));
                    case '-':
                        return (now) => left(now).minus(right(now));
                }
                break;
            case 'sugar_duration':
                const duration = this.compileToDuration(ast.value.operand);
                const from = (now) => base ? base(now) : now;
                switch (ast.value.op) {
                    case '+':
                        return (now) => from(now).plus(duration(now));
                    case '-':
                        return (now) => from(now).minus(duration(now));
                }
                break;
        }
        throw `Unable to compile ${JSON.stringify(ast)} to an Instant`;
    }
    compileToDuration(ast) {
        const duration = Duration.fromISO(ast.value);
        return (_now) => duration;
    }
    compileToMatcher(ast) {
        let start, end, other;
        switch (ast.type) {
            case 'year_instant':
                start = this.compileToInstant(ast);
                end = (now) => start(now).plus({ year: 1 });
                return new Matcher(this, start, end, true);
            case 'month_instant':
                start = this.compileToInstant(ast);
                end = (now) => start(now).plus({ month: 1 });
                return new Matcher(this, start, end, true);
            case 'date_instant':
                start = this.compileToInstant(ast);
                end = (now) => start(now).plus({ day: 1 });
                return new Matcher(this, start, end, true);
            case 'constant_instant':
                switch (ast.value) {
                    case 'BOT':
                    case 'SOT':
                        return new Matcher(this, sotFN, nowFn, true);
                    case 'NOW':
                        start = this.compileToInstant(ast);
                        end = (now) => start(now).plus({ minute: 1 });
                        return new Matcher(this, start, end, true);
                    case 'TODAY':
                        start = this.compileToInstant(ast);
                        end = (now) => start(now).plus({ day: 1 });
                        return new Matcher(this, start, end, true);
                    case 'YESTERDAY':
                        start = this.compileToInstant(ast);
                        end = (now) => start(now).plus({ day: 1 });
                        return new Matcher(this, start, end, true);
                    case 'TOMORROW':
                        start = this.compileToInstant(ast);
                        end = (now) => start(now).plus({ day: 1 });
                        return new Matcher(this, start, end, true);
                    case 'EOT':
                        return new Matcher(this, nowFn, eotFN, true);
                }
                break;
            case 'interval':
                start = this.compileToInstant(ast.value.start);
                end = this.compileToInstant(ast.value.end);
                return new Matcher(this, start, end, ast.value.exclusive);
            case 'iso_interval':
                start = this.compileToInstant(ast.value.start);
                end = this.compileToInstant(ast.value.end, start);
                return new Matcher(this, start, end, ast.value.exclusive);
            case 'sugar_constant':
                other = this.compileToInstant(ast.value.operand);
                switch (ast.value.op) {
                    case '+':
                        return new Matcher(this, nowFn, other, true);
                    case '-':
                        return new Matcher(this, other, nowFn, true);
                }
                break;
            case 'sugar_duration':
                other = this.compileToDuration(ast.value.operand);
                switch (ast.value.op) {
                    case '+':
                        return new Matcher(this, nowFn, (now) => now.plus(other(now)), true);
                    case '-':
                        return new Matcher(this, (now) => now.minus(other(now)), nowFn, true);
                }
                break;
        }
        throw `Unable to compile ${JSON.stringify(ast)} to a date matcher`;
    }
}
