angular
    .module('klaro')
    .directive('logoutForm', logoutForm);
function logoutForm() {
    return {
        restrict: 'E',
        scope: {
            variant: '@',
        },
        replace: true,
        template: require('@/auth/logoutForm.html'),
    };
}
