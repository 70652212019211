// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
function id(x) { return x[0]; }
import moo from 'moo';
const lexer = moo.compile({
    ws: {
        match: /\s+/,
        lineBreaks: true
    },
    date: {
        match: /[0-9]{4}-[0-9]{2}-[0-9]{2}/,
        value: text => text
    },
    month: {
        match: /[0-9]{4}-[0-9]{2}/,
        value: text => text
    },
    digits: {
        match: /[0-9]+/,
        value: text => text
    },
    inf: {
        match: /Inf/,
        value: text => text
    },
    duration: {
        match: /P(?!$)(?:\d+Y)?(?:\d+M)?(?:\d+W)?(?:\d+D)?(?:T(?:\d+H)?(?:\d+M)?(?:\d+S)?)?/,
        value: text => text
    },
    dots: {
        match: /\.{2,3}/,
        value: text => text
    },
    slash: '/',
    plus: '+',
    minus: '-',
    constant: [
        'YESTERDAY',
        'TODAY',
        'NOW',
        'TOMORROW',
        'BOT',
        'SOT',
        'SOH',
        'SOD',
        'SOW',
        'SOM',
        'SOQ',
        'SOY',
        'SOT',
        'EOH',
        'EOD',
        'EOW',
        'EOM',
        'EOQ',
        'EOY',
        'EOT',
    ],
});
let Lexer = lexer;
let ParserRules = [
    { 'name': 'int_range_expression', 'symbols': ['int_range_interval'], 'postprocess': id },
    {
        'name': 'int_range_interval', 'symbols': ['integer', '_', (lexer.has('dots') ? { type: 'dots' } : dots), '_', 'integer'], 'postprocess': d => ({
            type: 'int_interval',
            value: {
                start: d[0],
                end: d[4],
                exclusive: d[2].value === '...'
            }
        })
    },
    { 'name': 'integer', 'symbols': ['actual_integer'], 'postprocess': id },
    { 'name': 'integer', 'symbols': ['infinity'], 'postprocess': id },
    { 'name': 'actual_integer', 'symbols': [(lexer.has('minus') ? { type: 'minus' } : minus), (lexer.has('digits') ? { type: 'digits' } : digits)], 'postprocess': d => (-parseInt(d[1])) },
    { 'name': 'actual_integer', 'symbols': [(lexer.has('plus') ? { type: 'plus' } : plus), (lexer.has('digits') ? { type: 'digits' } : digits)], 'postprocess': d => (parseInt(d[1])) },
    { 'name': 'actual_integer', 'symbols': [(lexer.has('digits') ? { type: 'digits' } : digits)], 'postprocess': d => (parseInt(d[0])) },
    { 'name': 'infinity', 'symbols': [(lexer.has('minus') ? { type: 'minus' } : minus), (lexer.has('inf') ? { type: 'inf' } : inf)], 'postprocess': d => (Number.NEGATIVE_INFINITY) },
    { 'name': 'infinity', 'symbols': [(lexer.has('plus') ? { type: 'plus' } : plus), (lexer.has('inf') ? { type: 'inf' } : inf)], 'postprocess': d => (Number.POSITIVE_INFINITY) },
    { 'name': 'infinity', 'symbols': [(lexer.has('inf') ? { type: 'inf' } : inf)], 'postprocess': d => (Number.POSITIVE_INFINITY) },
    { 'name': 'time_range_expression', 'symbols': ['time_range_interval'], 'postprocess': id },
    { 'name': 'time_range_expression', 'symbols': ['time_range_iso_interval'], 'postprocess': id },
    { 'name': 'time_range_expression', 'symbols': ['instant'], 'postprocess': id },
    {
        'name': 'time_range_interval', 'symbols': ['instant', '_', (lexer.has('dots') ? { type: 'dots' } : dots), '_', 'instant'], 'postprocess': d => ({
            type: 'interval',
            value: {
                start: d[0],
                end: d[4],
                exclusive: d[2].value === '...'
            }
        })
    },
    {
        'name': 'time_range_iso_interval', 'symbols': ['instant', '_', (lexer.has('slash') ? { type: 'slash' } : slash), '_', 'instant'], 'postprocess': d => ({
            type: 'iso_interval',
            value: {
                start: d[0],
                end: d[4],
                exclusive: false,
            }
        })
    },
    { 'name': 'instant', 'symbols': ['simple_instant'], 'postprocess': id },
    { 'name': 'instant', 'symbols': ['computed_instant'], 'postprocess': id },
    { 'name': 'instant', 'symbols': ['sugar_instant'], 'postprocess': id },
    { 'name': 'simple_instant', 'symbols': ['date_instant'], 'postprocess': d => d[0] },
    { 'name': 'simple_instant', 'symbols': ['month_instant'], 'postprocess': d => d[0] },
    { 'name': 'simple_instant', 'symbols': ['year_instant'], 'postprocess': d => d[0] },
    { 'name': 'simple_instant', 'symbols': ['constant_instant'], 'postprocess': d => d[0] },
    {
        'name': 'date_instant', 'symbols': [(lexer.has('date') ? { type: 'date' } : date)], 'postprocess': d => ({
            type: 'date_instant',
            value: d[0].value
        })
    },
    {
        'name': 'month_instant', 'symbols': [(lexer.has('month') ? { type: 'month' } : month)], 'postprocess': d => ({
            type: 'month_instant',
            value: d[0].value
        })
    },
    {
        'name': 'year_instant', 'symbols': [(lexer.has('digits') ? { type: 'digits' } : digits)], 'postprocess': d => ({
            type: 'year_instant',
            value: d[0].value
        })
    },
    {
        'name': 'constant_instant', 'symbols': [(lexer.has('constant') ? { type: 'constant' } : constant)], 'postprocess': d => ({
            type: 'constant_instant',
            value: d[0].value
        })
    },
    { 'name': 'computed_instant$subexpression$1', 'symbols': [(lexer.has('plus') ? { type: 'plus' } : plus)] },
    { 'name': 'computed_instant$subexpression$1', 'symbols': [(lexer.has('minus') ? { type: 'minus' } : minus)] },
    {
        'name': 'computed_instant', 'symbols': ['simple_instant', '_', 'computed_instant$subexpression$1', '_', 'duration_expr'], 'postprocess': d => ({
            type: 'computed_instant',
            value: {
                left: d[0],
                op: d[2][0].type === 'plus' ? '+' : '-',
                right: d[4]
            }
        })
    },
    {
        'name': 'duration_expr', 'symbols': [(lexer.has('duration') ? { type: 'duration' } : duration)], 'postprocess': d => ({
            type: 'duration',
            value: d[0].value,
        })
    },
    { 'name': 'sugar_instant', 'symbols': ['minus_constant'], 'postprocess': d => d[0] },
    { 'name': 'sugar_instant', 'symbols': ['plus_constant'], 'postprocess': d => d[0] },
    { 'name': 'sugar_instant', 'symbols': ['minus_duration'], 'postprocess': d => d[0] },
    { 'name': 'sugar_instant', 'symbols': ['plus_duration'], 'postprocess': d => d[0] },
    { 'name': 'sugar_instant', 'symbols': ['implicit_plus_duration'], 'postprocess': d => d[0] },
    {
        'name': 'minus_constant', 'symbols': [(lexer.has('minus') ? { type: 'minus' } : minus), (lexer.has('constant') ? { type: 'constant' } : constant)], 'postprocess': d => ({
            type: 'sugar_constant',
            value: {
                op: '-',
                operand: { type: 'constant_instant', value: d[1].value },
            }
        })
    },
    {
        'name': 'minus_duration', 'symbols': [(lexer.has('minus') ? { type: 'minus' } : minus), (lexer.has('duration') ? { type: 'duration' } : duration)], 'postprocess': d => ({
            type: 'sugar_duration',
            value: {
                op: '-',
                operand: { type: 'duration', value: d[1].value },
            }
        })
    },
    {
        'name': 'plus_constant', 'symbols': [(lexer.has('plus') ? { type: 'plus' } : plus), (lexer.has('constant') ? { type: 'constant' } : constant)], 'postprocess': d => ({
            type: 'sugar_constant',
            value: {
                op: '+',
                operand: { type: 'constant_instant', value: d[1].value },
            }
        })
    },
    {
        'name': 'plus_duration', 'symbols': [(lexer.has('plus') ? { type: 'plus' } : plus), (lexer.has('duration') ? { type: 'duration' } : duration)], 'postprocess': d => ({
            type: 'sugar_duration',
            value: {
                op: '+',
                operand: { type: 'duration', value: d[1].value },
            }
        })
    },
    {
        'name': 'implicit_plus_duration', 'symbols': [(lexer.has('duration') ? { type: 'duration' } : duration)], 'postprocess': d => ({
            type: 'sugar_duration',
            value: {
                op: '+',
                operand: { type: 'duration', value: d[0].value },
            }
        })
    },
    { 'name': '_$ebnf$1', 'symbols': [] },
    { 'name': '_$ebnf$1', 'symbols': ['_$ebnf$1', (lexer.has('ws') ? { type: 'ws' } : ws)], 'postprocess': function arrpush(d) { return d[0].concat([d[1]]); } },
    { 'name': '_', 'symbols': ['_$ebnf$1'], 'postprocess': () => null }
];
let ParserStart = 'int_range_expression';
export default { Lexer, ParserRules, ParserStart };
