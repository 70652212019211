angular
    .module('klaro')
    .directive('addDescriptionWidget', addDescriptionWidget);
function addDescriptionWidget($timeout) {
    return {
        restrict: 'E',
        template: require('@/support/addDescriptionWidget.html'),
        scope: {
            formData: '=',
            focus: '=',
        },
        link: function (scope, elm) {
            scope.hasDescription = scope.formData.description && scope.formData.description.length > 0;
            scope.showDescriptionTextarea = function () {
                scope.hasDescription = !scope.hasDescription;
                focus();
            };
            if (scope.focus) {
                focus();
            }
            function focus() {
                // timeout necessary, since angular needs to refresh to install the widget
                // first
                $timeout(() => {
                    elm.find('textarea').focus();
                });
            }
        },
    };
}
