import axios from 'axios';
export * from './AngularAxiosApi';
export const createApiClient = (client, options) => {
    const axiosOptions = {
        ...(options || {}),
    };
    const axiosClient = axios.create(axiosOptions);
    axiosClient.withToken = (token) => {
        const optionsOverride = {
            ...axiosOptions,
            headers: {
                ...(axiosOptions.headers || {}),
                'Authorization': `${token.token_type} ${token.access_token}`,
            },
        };
        return createApiClient(client, optionsOverride);
    };
    axiosClient.interceptors.request.use((config) => {
        var _a;
        config.baseURL = client.store.apiOptions.baseURL;
        const { authToken } = client.store.session;
        if (authToken) {
            config.headers.Authorization = `${authToken.token_type} ${authToken.access_token}`;
        }
        (_a = config.headers)['X-Klaro-Project-Subdomain'] || (_a['X-Klaro-Project-Subdomain'] = client.store.apiOptions.subdomain || client.store.project?.subdomain || 'app');
        if (client.store.session.viewAsCode) {
            config.headers['X-Klaro-ViewAs'] = client.store.session.viewAsCode;
        }
        return config;
    });
    axiosClient.interceptors.response.use((res) => {
        return res;
    }, (err) => {
        throw err;
    });
    return axiosClient;
};
