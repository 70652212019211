import { User } from '@klaro/corejs/model';
angular
    .module('klaro')
    .controller('JoinModalController', JoinModalController);
function JoinModalController($scope, $http, $uibModalInstance, auth, token, robust, navigation) {
    $scope.step = null;
    $scope.langs = User.supportedLanguages;
    $scope.canBack = function () {
        return $scope.step == 'auth';
    };
    $scope.back = function () {
        $scope.step = 'about-you';
        $scope.joinRequest = {
            nickname: $scope.member.nickname,
            firstname: $scope.member.user.firstname,
            lastname: $scope.member.user.lastname,
            setPassword: !$scope.member.user.emailValidated,
            lang: $scope.member.user.lang || 'en',
        };
        $scope.isNewUser = !$scope.member.user.emailValidated;
    };
    $scope.error = null;
    $scope.success = false;
    $scope.member = null;
    $scope.isNewUser = null;
    $scope.passwordForgotten = null;
    auth.withToken(token, (done) => {
        $http
            .get('/api/auth/join/')
            .then((response) => {
            $scope.member = response.data;
            if ($scope.member.joinedAt !== null) {
                $scope.gotoHome();
            }
            else {
                $scope.back();
            }
            done();
        })
            .catch((response) => {
            onError(response);
            $scope.step = 'validation-failed';
            done();
        });
    });
    $scope.confirmAboutYou = function () {
        $scope.step = 'auth';
    };
    $scope.confirmAuth = function () {
        $scope.error = null;
        $scope.success = false;
        auth.withToken(token, (done) => {
            return $http
                .post('/api/auth/join/', $scope.joinRequest)
                .then(done)
                .then($scope.refresh)
                .catch((response) => {
                onError(response);
                done();
            });
        });
    };
    function close() {
        $uibModalInstance.close();
    }
    function onError(response) {
        $scope.error = robust.message(response);
    }
    $scope.gotoLogin = function () {
        close();
        navigation.gotoUrl('/auth/login');
    };
    $scope.gotoHome = function () {
        close();
        navigation.gotoHome();
    };
    $scope.refresh = function () {
        close();
        navigation.refresh();
    };
    $scope.gotoPasswordForgotten = function () {
        $scope.passwordForgotten = true;
        $scope.joinRequest.setPassword = true;
    };
}
