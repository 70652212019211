angular
    .module('klaro')
    .service('storySelectionEditModal', storySelectionEditModal)
    .controller('StorySelectionEditModalController', StorySelectionEditModalController);
function storySelectionEditModal(klaroModal) {
    return {
        open: function (boardCtrl, options) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/story/storySelectionEditModal.html'),
                controller: 'StorySelectionEditModalController',
                windowClass: 'klaro-modal story-selection-edit-modal',
                size: 'small full-mobile',
                resolve: {
                    boardCtrl: function () {
                        return boardCtrl;
                    },
                    options: function () {
                        return options;
                    },
                },
            });
        },
    };
}
function StorySelectionEditModalController($scope, $uibModalInstance, boardCtrl) {
    $scope.editableDimensions = boardCtrl.getDimensions()
        .filter(d => d.canBeEditedAsCardDimension());
    $scope.patch = {};
    $scope.selectionSize = boardCtrl.selectionSize.bind(boardCtrl);
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.close = function () {
        $uibModalInstance.close();
    };
    $scope.canSave = function () {
        return Object.keys($scope.patch).length > 0;
    };
    $scope.save = function () {
        boardCtrl
            .applyPatchToSelection($scope.patch)
            .then($scope.close);
    };
}
