angular
    .module('klaro')
    .service('boardInviteModal', boardInviteModal)
    .controller('BoardInviteModalController', BoardInviteModalController);
function boardInviteModal(klaroModal, workspaceRest) {
    return {
        open: function (boardCtrl) {
            return klaroModal.open({
                animation: false,
                ariaLabelledBy: 'modal-title',
                ariaDescribedBy: 'modal-body',
                template: require('@/core/board/boardInviteModal.html'),
                controller: 'BoardInviteModalController',
                windowClass: 'klaro-modal invite-board-modal',
                size: 'small',
                resolve: {
                    boardCtrl: function () {
                        return boardCtrl;
                    },
                    workspaces: function () {
                        return workspaceRest.list();
                    },
                },
            });
        },
    };
}
function BoardInviteModalController($scope, $uibModalInstance, boardCtrl, workspaces, boardRest, robust, boardWorkspaceNewModal, workspaceRest) {
    $scope.boardCtrl = boardCtrl;
    $scope.workspaces = workspaces;
    $scope.search = '';
    $scope.step = 'search';
    $scope.userWorkspaces = [];
    $scope.selectedUser = {};
    $scope.selectedWorkspaces = {};
    $scope.boardName = $scope.boardCtrl.getBoardLabel();
    $scope.cancel = function () {
        $uibModalInstance.dismiss();
    };
    $scope.close = $scope.cancel;
    $scope.back = function () {
        if ($scope.step === 'configure') {
            if ($scope.many.addresses) {
                $scope.unselectMany();
            }
            else {
                $scope.unselect();
            }
        }
        else {
            $scope.many = {};
            $scope.search = '';
            $scope.step = 'search';
        }
    };
    $scope.newWorkspaceModal = function (workspace) {
        return boardWorkspaceNewModal
            .open($scope.boardCtrl, workspace)
            .result
            .then((response) => {
            $scope.selectedWorkspaces[response.code] = true;
            getWorkspaces();
        })
            .catch(onError);
    };
    $scope.canInvite = function () {
        return $scope.inviteForm.$valid && $scope.couldInvite();
    };
    /**
     * Invitation can be sent when the selected user has access to at least one workspace
     * that has permission != forbidden
     **/
    $scope.couldInvite = function () {
        return getSelectedWorkspaceCodes().reduce((acc, cur) => {
            return acc || !$scope.boardCtrl.getBoardState().isForbiddenTo(cur);
        }, false);
    };
    $scope.invite = function () {
        $scope.error = '';
        const ws = getSelectedWorkspaceCodes().map((cur) => {
            return { code: cur };
        });
        let data;
        if ($scope.many.addresses) {
            data = {
                emailList: $scope.many.addresses,
                workspaces: ws,
            };
        }
        else {
            data = {
                email: $scope.selectedUser.email,
                firstname: $scope.selectedUser.firstname,
                lastname: $scope.selectedUser.lastname,
                workspaces: ws,
            };
        }
        boardRest.invite(boardCtrl.getBoardState(), data)
            .then(() => {
            $scope.step = 'success';
        })
            .catch(onError);
    };
    function onError(err) {
        if (err && err.data) {
            $scope.error = robust.message(err.data, false);
        }
    }
    function getWorkspaces() {
        workspaceRest
            .list()
            .then((workspaces) => {
            $scope.workspaces = workspaces;
        });
    }
    function getSelectedWorkspaceCodes() {
        return Object
            .keys($scope.selectedWorkspaces)
            .filter((cur) => {
            return $scope.selectedWorkspaces[cur];
        });
    }
}
