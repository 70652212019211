import { DataObject, Thumbnails } from '../utils';
import User from './User';
class Project extends DataObject {
    static dress(raw) {
        if (raw.constructor && raw.constructor == this) {
            return raw;
        }
        const owner = raw.owner ? User.dress(raw.owner) : undefined;
        return new Project(Object.assign({}, raw, {
            owner: owner,
        }));
    }
    get logoThumbUrl() {
        return Thumbnails(this.logoUrl, 'logo');
    }
    assetUrl(path, globalContext) {
        let assetUrl;
        if (path.indexOf('http') === 0) {
            // We don't touch fully qualified urls
            return path;
        }
        else if (this.imgBaseUrl || this.url) {
            // This is the new normal: use what the backend provides
            assetUrl = [
                (this.imgBaseUrl || this.url).replace(/\/$/, ''),
                path.replace(/^\//, '')
            ].join('/');
        }
        else if (globalContext && globalContext.apiOptions) {
            // Backward compatibility: hack apiOptions & subdomain
            const subdomain = this.subdomain;
            const apiBaseUrl = globalContext.apiOptions.baseURL;
            const objectUrl = new URL(apiBaseUrl);
            const [_app, ...parts] = objectUrl.hostname.split('.');
            objectUrl.hostname = [subdomain, ...parts].join('.');
            objectUrl.pathname = path;
            assetUrl = objectUrl.toString();
        }
        else {
            console.log(globalContext);
            console.error('Unable to find the instance domain, fallback on klaro.cards');
            // Nothing is known ? let's say we are on major domain
            assetUrl = [
                `https://${this.subdomain}.klaro.cards`,
                path.replace(/^\//, '')
            ].join('/');
        }
        return assetUrl;
    }
}
export default Project;
