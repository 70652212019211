import { DateTime } from 'luxon';
import { TimeRangeExprParser } from '../../lang';
import Dimension from '../Dimension';
import Scalar from './Mixins/Scalar';
import TimeUtils from '../../utils/TimeUtils';
const DATE_RANGE_EXPR_CACHE = {};
const parser = new TimeRangeExprParser();
class DateDim extends Dimension {
    inTimezone(date, globalContext) {
        if (date && globalContext && globalContext.project) {
            date = date.setZone(globalContext.project.timezone);
            date = date.setLocale(globalContext.project.locale);
        }
        return date;
    }
    getNow(globalContext) {
        globalContext ?? (globalContext = {});
        return globalContext.today || TimeUtils.today();
    }
    static getRawFromHighValue(high) {
        return high ? high.toISO() : null;
    }
    /**
      * Overriden to return a DateTime for the actual date.
      */
    getStoryHighLevelValue(story, globalContext) {
        const storyValue = this.getStoryRawValue(story);
        const date = storyValue ? story._cached(this.code, () => {
            if (storyValue instanceof Date) {
                return DateTime.fromJSDate(storyValue);
            }
            return DateTime.fromISO(storyValue);
        }) : null;
        return this.inTimezone(date, globalContext);
    }
    getUTCDate(story) {
        const utcDate = this.getStoryHighLevelValue(story, { project: { timezone: 'utc+1' } }) ?? null;
        return utcDate ? utcDate.toISO() : '';
    }
    getStoryHumanValue(story, globalContext) {
        const date = this.getStoryHighLevelValue(story, globalContext);
        return date ? date.toLocaleString(DateTime.DATE_SHORT) : '';
    }
    /**
     * Returns a patch to apply on a story to get it having the specific value.
     */
    getPatchForStoryValueTo(value, globalContext, now) {
        now ?? (now = this.getNow(globalContext));
        const matcher = DateDim.getMatcherForExpr(value.semantics, globalContext);
        const update = {};
        if (matcher && matcher.valueFor) {
            update[this.code] = matcher.valueFor(now).toUTC().toISO();
        }
        return update;
    }
    /**
      * Overriden to take the earliest date defining `dimValue`'s date range and
      * setting it as story raw dimension value.
      */
    withStoryValueTo(story, dimValue, globalContext, now) {
        const update = this.getPatchForStoryValueTo(dimValue, globalContext, now);
        return story.clone(update);
    }
    /**
      * Overriden to implement the date range semantics: the match occurs when
      * the raw dimension value is a date within `dimValue`'s range.
      */
    storyValueMatches(story, dimValue, globalContext, now) {
        now ?? (now = this.getNow(globalContext));
        const dateTime = this.getStoryHighLevelValue(story, globalContext);
        if (dateTime == null) {
            return dimValue.id == null;
        }
        if (dimValue.id == null) {
            return false;
        }
        const matcher = DateDim.getMatcherForExpr(dimValue.semantics, globalContext);
        return matcher.match(dateTime, now);
    }
    /**
      * Overriden to use a datepicker as input widget.
      */
    getInputWidgetCode() {
        return 'datepicker';
    }
    getTableAlignmentClass() {
        return 'align-right';
    }
    usesTime() {
        return false;
    }
    canBeUsedAsDateBoundary() {
        return true;
    }
    // Static tools on expressions
    static getMatcherForExpr(expr, globalContext) {
        if (DATE_RANGE_EXPR_CACHE[expr] === undefined) {
            DATE_RANGE_EXPR_CACHE[expr] = DateDim.compileExpr(expr, globalContext);
        }
        return DATE_RANGE_EXPR_CACHE[expr];
    }
    static validExpr(expr) {
        if (!expr) {
            return false;
        }
        try {
            const r = parser.parse(expr);
            return r !== undefined;
        }
        catch (ex) {
            return false;
        }
    }
    static compileExpr(expr, globalContext) {
        const parser = new TimeRangeExprParser(globalContext?.project?.timezone, globalContext?.project?.locale);
        const ast = parser.parse(expr);
        const matcher = parser.compileToMatcher(ast);
        return matcher;
    }
}
Object.assign(DateDim.prototype, Scalar);
export default DateDim;
