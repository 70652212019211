import { DataObject } from '../utils';
class User extends DataObject {
    static get supportedLanguages() {
        return [
            { code: 'en', name: 'English' },
            { code: 'fr', name: 'Français' },
        ];
    }
    get syncMode() {
        return (this.preferences || {}).syncMode;
    }
    static dress(raw) {
        if (raw.constructor && raw.constructor === this) {
            return raw;
        }
        return new User(raw);
    }
}
export default User;
