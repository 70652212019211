import Dimension from '../Dimension';
import StoryDecorator from '../../lang/StoryDecorator';
import Evaluator from '../../lang/Evaluator';
class Computed extends Dimension {
    get category() {
        return 'advanced';
    }
    // Must be overriden by subclasses
    getBase() {
        throw new Error('Computed#getBase() must be implemented');
    }
    getStorySorter(reverse, globalContext) {
        return (a, b) => {
            const aV = this.getStoryHighLevelValue(reverse ? b : a, globalContext);
            const bV = this.getStoryHighLevelValue(reverse ? a : b, globalContext);
            if (!aV && !bV) {
                return 0;
            }
            else if (!aV) {
                return 1;
            }
            else if (!bV) {
                return -1;
            }
            else {
                return aV - bV;
            }
        };
    }
    canBeEdited() {
        return false;
    }
    getStoryRawValue(story) {
        const high = this.getStoryHighLevelValue(story);
        return this.getBase().getRawFromHighValue(high);
    }
    getStoryHighLevelValue(story, globalContext) {
        return story._cached(this.code, () => {
            try {
                return this.compiledFn()(new StoryDecorator(story, globalContext));
            }
            catch {
                return undefined;
            }
        });
    }
    matchingValuesOf(story, globalContext) {
        return this.values.filter((v) => {
            return this.storyValueMatches(story, v, globalContext);
        });
    }
    firstMatchingValueOf(story, globalContext) {
        return this.values.find((v) => {
            return this.storyValueMatches(story, v, globalContext);
        });
    }
    storyValueMatches(story, dimValue, _globalContext) {
        const high = this.getStoryHighLevelValue(story);
        if (high === null) {
            return dimValue.id === null;
        }
        if (dimValue.id === null) {
            return false;
        }
        const matcher = this.getBase().getMatcherForExpr(dimValue.semantics);
        return matcher.match(high, _globalContext ? _globalContext.now : undefined);
    }
    usesSemantics() {
        return true;
    }
    requiresFormaldef() {
        return true;
    }
    ///
    compiledFn() {
        if (!this._compiledFn) {
            const formaldef = this.datatypeOptions && this.datatypeOptions.formaldef;
            try {
                this._compiledFn = (new Evaluator()).compile(formaldef || 'false');
            }
            catch (ex) {
                console.log(`Unable to compile expression: ${ex.message}`);
                console.log(ex.stack);
                console.log(formaldef);
                this._compiledFn = function () { return null; };
            }
        }
        return this._compiledFn;
    }
}
export default Computed;
