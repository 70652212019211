angular
    .module('klaro')
    .controller('SignUpModalController', SignUpModalController);
function SignUpModalController($scope, auth, navigation, $uibModalInstance, $location) {
    const ERROR_MSG = {
        'password-violates-policy': 'Your password is too easy to guess. Please choose a stronger one.',
        'terms-must-be-accepted': 'Please accept the terms & conditions',
        'sign-up-not-allowed': 'Free sign up is not available on this Klaro Cards instance',
    };
    $scope.signUpData = {
        lang: $location.search().lang || 'en',
        redirectTo: $location.search().redirectTo,
    };
    $scope.error = ERROR_MSG[$location.search().err];
    $scope.gotoLogin = function () {
        $uibModalInstance.close();
        navigation.gotoUrl('/auth/login', $location.search());
        return false;
    };
    auth.getOmniConfig({
        lang: $scope.signUpData.lang,
        redirectTo: $scope.signUpData.redirectTo,
    }).then((config) => {
        $scope.omniAuthConfig = config;
    });
}
