angular
    .module('klaro')
    .directive('storyList', storyList);
function storyList(attachmentsService, storyCardActions, KernelClient) {
    return {
        restrict: 'E',
        template: require('@/core/story/storyList.html'),
        scope: {
            'onClick': '&',
            'canWrite': '=',
        },
        require: '^board',
        link: function (scope, elm, attrs, board) {
            scope.inBoard = board;
            function onBoardChanged() {
                scope.listView = board.getBoardState().listView;
                scope.selectedStories = board.selectedStoriesIds().reduce((a, v) => ({ ...a, [v]: true }), {});
            }
            board.$watch('board', onBoardChanged, scope);
            scope.sortBy = function ($event, dim) {
                $event.preventDefault();
                $event.stopPropagation();
                board.sortBy(dim, board.isSortedByDesc(dim) || !board.isSortedBy(dim));
            };
            scope.sortAscending = function ($event, dim) {
                $event.preventDefault();
                $event.stopPropagation();
                board.sortBy(dim, true);
            };
            scope.switchToKanbanBy = function ($event, dim) {
                $event.preventDefault();
                $event.stopPropagation();
                board.switchToKanbanBy(dim);
            };
            scope.sortDescending = function ($event, dim) {
                $event.preventDefault();
                $event.stopPropagation();
                board.sortBy(dim, false);
            };
            scope.isSortedBy = board.isSortedBy;
            scope.isSortedByDesc = board.isSortedByDesc;
            scope.highlight = function (story) {
                board.highlight(story);
            };
            scope.unhighlight = function () {
                board.unhighlight();
            };
            scope.getStoryColorClass = function (story) {
                return board.getStoryColorClass(story);
            };
            scope.showCoverImage = function () {
                return board.getBoardState().showCoverImage();
            };
            scope.getCoverImageUrl = function (story) {
                const url = story.getCoverImageUrl(board.getBoardState(), KernelClient.store);
                return url ? `url(${url})` : null;
            };
            scope.hasSelection = function () {
                return board.hasStorySelection();
            };
            scope.isStorySelected = function (story) {
                return board.isStorySelected(story);
            };
            scope.toggleStorySelection = function (story) {
                return board.toggleStorySelection(story);
            };
            scope.onDimensionCreated = function (dimension) {
                board.addNewDimension(dimension);
            };
            scope.onDrop = function (story, targetIndex) {
                board.moveStoryInCustomOrder(story, targetIndex);
            };
            scope.toggleOpening = function (row, $event) {
                $event.preventDefault();
                $event.stopPropagation();
                board.toggleListViewOpening(row);
            };
            scope.addStoryInHierarchy = function (row, $event) {
                if (!board.canWrite() || board.hasStorySelection()) {
                    return;
                }
                $event.preventDefault();
                $event.stopPropagation();
                const defaults = scope.listView.getDefaultValuesFor(row);
                if ($event.ctrlKey) {
                    board.openNewStoryModal(defaults, false);
                }
                else {
                    const dimension = scope.listView.hierarchyDimensionFor(row);
                    board.openLinkStoryModal(dimension, row.story);
                }
            };
            scope.fileUploaded = function (promise, story) {
                return attachmentsService.fileUploadHandler(scope.inBoard, story, promise);
            };
            scope.openCardActionsMenu = function (e, story, showSelectionCheckboxes) {
                storyCardActions
                    .open(scope.inBoard, story, e.target, showSelectionCheckboxes);
            };
        },
    };
}
