import { IdentifierCandidate } from './IdentifierCandidate';
import { IntegerCandidate } from './IntegerCandidate';
import { UserDefinedCandidate } from './UserDefinedCandidate';
import { DateCandidate } from './DateCandidate';
import { TitleCandidate } from './TitleCandidate';
import { TextCandidate } from './TextCandidate';
import { ToggleCandidate } from './ToggleCandidate';
import { SpecificationCandidate } from './SpecificationCandidate';
export * from './DimensionCandidate';
export * from './SpecificationCandidate';
export * from './IdentifierCandidate';
export * from './IntegerCandidate';
export * from './UserDefinedCandidate';
export * from './DateCandidate';
export * from './TitleCandidate';
export * from './ToggleCandidate';
const Candidates = [
    TitleCandidate,
    IdentifierCandidate,
    SpecificationCandidate,
    UserDefinedCandidate,
    IntegerCandidate,
    DateCandidate,
    ToggleCandidate,
    TextCandidate,
];
export const klarifyDimensions = (data) => {
    const dimCandidates = {};
    let attributes = new Set();
    data.forEach((row, idx) => {
        attributes = new Set([...attributes, ...Object.keys(row)]);
        for (const attr of attributes) {
            const cellValue = row[attr];
            if (!dimCandidates[attr]) {
                dimCandidates[attr] = Candidates.reduce((cds, clazz) => {
                    const candidate = new clazz(attr);
                    // When facing a new attribute, we already inform the dimension that it
                    // supports undefined
                    // (and is therefore not required as the previous rows didn't have the attribute)
                    if (idx > 0) {
                        candidate.addValue(undefined);
                    }
                    cds.push(candidate);
                    return cds;
                }, []);
            }
            dimCandidates[attr].forEach(cdt => cdt.addValue(cellValue));
        }
    });
    const dimensions = Object.entries(dimCandidates)
        .reduce((dims, [attr, cdts]) => {
        const bets = cdts
            .map((c) => [c, c.placeBet()])
            .sort((a, b) => {
            return b[1] - a[1];
        });
        const [[winner]] = bets;
        dims[attr] = winner;
        return dims;
    }, {});
    return Object.values(dimensions).map(d => d.deliver());
};
