import { DataObject } from '../../utils';
class Permissions extends DataObject {
    isViewAsCode(code) {
        return this.session.viewAs.code === code;
    }
    get isAdmin() {
        return this.memoize('isAdmin', '', () => {
            const user = this.session.user;
            if (!user || !user.workspaces) {
                return false;
            }
            return user.workspaces.find(t => t.code === 'admins');
        });
    }
    get dimensions() {
        return this.memoize('dimensions', '', () => {
            return {
                mayCreate: this.isAdmin,
                mayCreateValue: (dimension) => this.isAdmin,
            };
        });
    }
    get cards() {
        return this.memoize('cards', '', () => {
            return {
                mayArchive: (board) => board.getArchivedDimension() || this.isAdmin,
                mayDuplicate: (board) => true,
            };
        });
    }
}
export default Permissions;
