import { DomainUtils } from '@klaro/corejs/utils';
angular
    .module('klaro')
    .controller('NewProjectModalController', NewProjectModalController);
function NewProjectModalController($scope, $window, $location, $http, $routeParams, robust, auth, $httpParamSerializer) {
    const normalizeSubdomain = DomainUtils.normalizeSubdomain;
    let customSubdomain = false;
    $scope.user = auth.getUser();
    if ($routeParams.redirected) {
        $scope.error = "You have been redirected to your company's private hosting area.";
    }
    else if ($routeParams['orphan-user']) {
        $scope.error = 'You no longer have any project. How about creating one ?';
    }
    else {
        $scope.error = null;
    }
    $scope.formData = {
        name: $routeParams.name,
        subdomain: normalizeSubdomain($routeParams.subdomain || $routeParams.name),
        template: $routeParams.template,
    };
    $scope.projectUrlPrefix = `${$location.protocol()}://`;
    $scope.projectUrlSuffix = `.${$location.host().replace(/^[^.]+\./, '')}`;
    $scope.confirmProject = function () {
        submit()
            .then((response) => {
            const data = response.data;
            $window.location = data.location;
        })
            .catch((e) => {
            if (e.status === 421 && e.headers('location')) {
                const params = $httpParamSerializer({
                    name: $scope.formData.name,
                    subdomain: $scope.formData.subdomain,
                    redirected: true,
                });
                $window.location.href = `${e.headers('location')}/new?${params}`;
            }
        });
    };
    function submit() {
        $scope.error = null;
        // Ok, this will ensure a valid subdomain anyway.
        const data = angular.extend({ dryRun: false }, $scope.formData);
        return $http
            .post('/api/', data, {
            skipAuthErrorHandling: true,
        })
            .catch(onError);
    }
    function onError(err) {
        if (err.status !== 421) {
            $scope.error = robust.message(err);
        }
        throw err;
    }
    // Subdomain handling
    $scope.$watch('formData.name', (newName) => {
        if ($scope.formData.subdomain && $scope.formData.subdomain.length > 0) {
            if (customSubdomain) {
                return;
            }
            if (!newName) {
                return;
            }
            if ($routeParams.subdomain) {
                return;
            }
        }
        $scope.formData.subdomain = normalizeSubdomain(newName);
    });
    $scope.customSubdomain = function ($event) {
        const keyTyped = String.fromCharCode($event.which);
        if (keyTyped.match(/[A-Z0-9-]/)) {
            customSubdomain = true;
        }
        $scope.newProjectForm.subdomain.$setDirty();
    };
}
