import nearley from 'nearley';
import grammar from './Parser.ne.js';
class Matcher {
    constructor(parser, start, end, exclusive) {
        this.parser = parser;
        this.start = start;
        this.end = end;
        this.exclusive = exclusive;
        this.compiled = this.compile(start, end, exclusive);
    }
    compile(start, end, exclusive) {
        const lte = (a) => start <= a;
        const gte = (a) => a <= end;
        const gt = (a) => a < end;
        return (a) => exclusive ? lte(a) && gt(a) : lte(a) && gte(a);
    }
    match(against) {
        return this.compiled(against);
    }
    valueFor() {
        switch (this.start) {
            case Number.NEGATIVE_INFINITY:
            case Number.POSITIVE_INFINITY:
                return undefined;
            default:
                return this.start;
        }
    }
}
export class IntRangeExprParser {
    constructor() {
        this.parser = IntRangeExprParser.aParser();
    }
    parse(input) {
        try {
            this.parser.feed(input);
            return this.parser.results[0];
        }
        finally {
            this.parser = IntRangeExprParser.aParser();
        }
    }
    compileToMatcher(ast) {
        switch (ast.type) {
            case 'int_interval':
                return new Matcher(this, ast.value.start, ast.value.end, ast.value.exclusive);
        }
        throw `Unable to compile ${JSON.stringify(ast)} to a Int interval`;
    }
    static aParser() {
        const g = nearley.Grammar.fromCompiled(grammar);
        g.start = 'int_range_expression';
        return new nearley.Parser(g);
    }
}
