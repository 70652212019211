angular
    .module('klaro')
    .directive('storyDimensionEditList', storyDimensionEditList);
function storyDimensionEditList() {
    return {
        restrict: 'E',
        template: require('@/core/story/storyDimensionEditList.html'),
        scope: {
            editableDimensions: '=',
            patch: '=',
        },
        link: function (scope, elm, attrs) {
            scope.selectedDimension = null;
            scope.editedDimensions = [];
            scope.$watch('selectedDimension', (dim) => {
                if (!dim) {
                    return;
                }
                if (scope.editedDimensions.find(d => d.id === dim.id)) {
                    return;
                }
                scope.editedDimensions.push(dim);
                scope.selectedDimension = null;
            });
            scope.removeDimension = function (dimension) {
                scope.editedDimensions = scope.editedDimensions.filter(d => d.id !== dimension.id);
                delete scope.patch[dimension.code];
            };
        },
    };
}
