import { TimeUtils } from '../../utils';
import Stories from '../../model/Board/Stories';
import AbstractBoardListener from '../AbstractBoardListener';
export default class EmptyStoriesListener extends AbstractBoardListener {
    stateChanged(subject, newState, oldState) {
        return new Promise((resolve, _reject) => {
            if (!oldState) {
                const stories = Stories.dress([], {
                    board: newState.board,
                    globalContext: {
                        session: { user: newState.user },
                        board: newState.board,
                        now: TimeUtils.now(),
                        today: TimeUtils.today(),
                    },
                });
                resolve(newState.clone({
                    stories: stories,
                    storiesPredicate: newState.original.getFilters(),
                }));
            }
            else {
                resolve(newState);
            }
        });
    }
}
