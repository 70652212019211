angular
    .module('klaro')
    .directive('omniGoogleBtn', omniGoogleBtn);
function omniGoogleBtn() {
    return {
        restrict: 'E',
        scope: {
            btnTitle: '@',
        },
        replace: true,
        template: require('@/auth/omni/googleBtn.html'),
    };
}
