angular
    .module('klaro')
    .service('Chatbot', Chatbot);
function Chatbot($injector, $rootScope, auth, Project) {
    let chatbot = null;
    let state = 'closed';
    return {
        hasChatbot: hasChatbot,
        setChatbot: setChatbot,
        open: open,
        close: close,
        toggle: toggle,
        isOpen: isOpen,
        isClosed: isClosed,
        install: install,
    };
    function hasChatbot() {
        return !!chatbot;
    }
    function setChatbot(c) {
        chatbot = c;
    }
    function install() {
        // $rootScope.$on('$routeChangeSuccess', () => {
        //   chatbot.routeChanged();
        // });
        // $rootScope.$on('klaro.user.changed', () => {
        //   setContext();
        // });
        // setContext();
    }
    // Proxy over actual chatbot
    function isOpen() {
        return state === 'open';
    }
    function isClosed() {
        return state === 'closed';
    }
    function open() {
        if (!chatbot) {
            install();
        }
        if (chatbot) {
            chatbot.open();
        }
    }
    function close() {
        if (!chatbot) {
            return;
        }
        if (chatbot) {
            chatbot.close();
        }
    }
    function toggle() {
        if (isClosed()) {
            open();
            state = 'open';
        }
        else {
            close();
            state = 'closed';
        }
    }
    // Private methods
    function setContext() {
        if (!chatbot) {
            return;
        }
        const user = auth.getUser();
        Project.get().then((project) => {
            chatbot.setContext(project, user);
        });
    }
}
