angular
    .module('klaro')
    .directive('passwordWidget', passwordWidget);
function passwordWidget() {
    return {
        restrict: 'E',
        scope: {
            ngModel: '=',
            label: '@',
        },
        replace: false,
        template: require('@/auth/passwordWidget.html'),
        link: function (scope) {
            scope.showPassword = false;
            scope.togglePassword = function () {
                scope.showPassword = !scope.showPassword;
            };
        },
    };
}
