import { Project } from '../../../model';
export class ApiProjectConnector {
    constructor(client) {
        this.client = client;
    }
    async get(subdomain) {
        const res = await this.client.api.get('/', {
            headers: {
                'X-Klaro-Project-Subdomain': subdomain || this.client.store.project?.subdomain,
            }
        });
        return Project.dress(res.data);
    }
}
